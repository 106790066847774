import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import type { ITerms } from 'typings/commonTypes'
import type { ITax } from 'typings/orderTypes'
import type { ICartItem } from 'typings/cartTypes'
import type {
  BasketStatus,
  IAdditionalCharge,
  IApplyPromoCodeResponse,
  IBasicBasketItem,
  IBasketWithDeliveryAndItemDetails,
  IBasketWithItemDetails,
  IDeliveryAddress,
  IDetailedDeliveryOption,
  MidnightMadnessPointAllocationMonth
} from 'typings/checkoutApi'
import type { CustomErrorMessage, IApiError } from 'typings/errorTypes'

import config from 'config'
import { bufferReducer, mergeRemoteBasketWithBuffer } from 'utils/cartUtils'
import { applyPromoCode, fetchCheckoutCartContent, setBasketContent } from 'services/checkout'
import { useAuthContext } from 'context/auth'
import { useAppContext } from 'context/app'

import { showToast } from 'components/layout/ToastNotification'
import { useLocalizationContext } from 'context/localization'

type DestinationType = 'virtual-warehouse' | 'immediate-purchase'

interface ICheckoutContext {
  readonly isBasketLoading: boolean
  readonly isBasketQueryError: boolean
  readonly basketQueryError: IApiError | null
  readonly shopId: string
  readonly shopUrl: string
  readonly cartCurrency: string
  readonly cartItems: ICartItem[]
  readonly addUnitsToCart: (articleNumber: string, quantity: number) => void
  readonly removeUnitsFromCart: (articleNumber: string, quantity: number) => void
  readonly setCartArticleUnits: (articleNumber: string, quantity: number) => void
  readonly removeArticleFromCart: (articleNumber: string) => void
  readonly emptyCart: () => void
  readonly isCartUploading: boolean
  readonly cartStatus: BasketStatus | undefined
  readonly isPaid: boolean
  readonly isCanceled: boolean
  readonly deliveryMethods: IDetailedDeliveryOption[]
  readonly eligibleDeliveryMethods: IDetailedDeliveryOption[]
  readonly pickupDeliveryMethods: IDetailedDeliveryOption[]
  readonly nonPickupDeliveryMethods: IDetailedDeliveryOption[]
  readonly allowsVirtualWarehouseDelivery: boolean
  readonly askAboutMidnightMadness: boolean
  readonly goesToVirtualWarehouse: boolean
  readonly isPickupSelected: boolean
  readonly setIsPickupSelected: Dispatch<SetStateAction<boolean>>
  readonly idOfSelectedShippingMethod: string | undefined
  readonly setIdOfSelectedShippingMethod: Dispatch<SetStateAction<string | undefined>>
  readonly defaultAddress: IDeliveryAddress | undefined
  readonly itemValidationErrorMap: Map<string, CustomErrorMessage> | undefined
  readonly setItemValidationErrorMap: Dispatch<
    SetStateAction<Map<string, CustomErrorMessage> | undefined>
  >
  readonly conditions: ITerms[]
  readonly additionalCharges: IAdditionalCharge[]
  readonly totalDeliveryCharges: number
  readonly cartSubtotal: number
  readonly totalTaxes: number
  readonly taxGroups: ITax[]
  readonly cartPoints: number
  readonly totalPaymentDue: number
  readonly undiscountedTotal: number
  readonly selectedDestination: DestinationType | undefined
  readonly setSelectedDestination: Dispatch<SetStateAction<DestinationType | undefined>>
  readonly midnightMadnessPointAllocationMonth: MidnightMadnessPointAllocationMonth | undefined
  readonly setMidnightMadnessPointAllocationMonth: Dispatch<
    SetStateAction<MidnightMadnessPointAllocationMonth | undefined>
  >
  readonly cartContentQueryKey: string[]
  readonly checkoutCartContentQueryKey: string[]
  readonly affiliateDiscount?: number
  readonly basketPromoCode?: string
  readonly isBasketPromoCodeLoading: boolean
  readonly isBasketPromoCodeError: boolean
  readonly basketPromoCodeError: IApiError | null
  readonly setBasketPromoCode: (promoCode: string) => void
  readonly promoCodeErrorMessage: string
}

interface IECPProps {
  readonly children: ReactNode
}

const cartBufferSynchronizationDebounceSpan = config.cartBufferSynchronizationDebounceSpan

const CheckoutContext = createContext<ICheckoutContext | null>(null)

export const CheckoutProvider: FC<IECPProps> = ({ children }) => {
  const {
    localeCode: localeCodePathParam,
    cartId: cartIdPathParam,
    vcartId: vcartIdPathParam
  } = useParams()
  const { appLocale } = useLocalizationContext()
  const { t: translated } = useTranslation()
  const queryClient = useQueryClient()
  const { accessToken } = useAuthContext()
  const {
    configuredLanguages,
    setConfiguredLanguages,
    setIsPromoCodeEnabled,
    setShopId,
    shopId,
    userPromoCode
  } = useAppContext()

  const cartId = cartIdPathParam || vcartIdPathParam || ''

  const cartBufferDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  /**
   * The cartBuffer temporarily stores the items added by the user.
   * Its content is submitted to the checkout API after a debounce period.
   */
  const [cartBuffer, bufferDispatch] = useReducer(bufferReducer, new Map())

  const [itemValidationErrorMap, setItemValidationErrorMap] = useState<
    Map<string, CustomErrorMessage> | undefined
  >()
  const [selectedDestination, setSelectedDestination] = useState<DestinationType | undefined>()
  const [idOfSelectedShippingMethod, setIdOfSelectedShippingMethod] = useState<string | undefined>()
  const [isPickupSelected, setIsPickupSelected] = useState(false)
  const [midnightMadnessPointAllocationMonth, setMidnightMadnessPointAllocationMonth] =
    useState<MidnightMadnessPointAllocationMonth>()
  const [inputPromoCode, setInputPromoCode] = useState('')
  const activePromoCode = inputPromoCode.length ? inputPromoCode : userPromoCode

  const promoCodeErrorMessage = translated('Promo code {{promoCode}} could not be applied', {
    promoCode: activePromoCode
  })

  const cartContentQueryKey = useMemo(
    () => ['cart', cartId, localeCodePathParam ?? ''],
    [localeCodePathParam, cartId]
  )
  const checkoutCartContentQueryKey = useMemo(
    () => ['cart', cartId, localeCodePathParam ?? '', 'checkout'],
    [cartId, localeCodePathParam]
  )

  const {
    data: basketData,
    error: basketQueryError,
    isLoading: isBasketLoading,
    isSuccess: isBasketQuerySuccess,
    isError: isBasketQueryError
  } = useQuery<IBasketWithDeliveryAndItemDetails, IApiError>({
    enabled: !!cartId && !!accessToken,
    queryKey: checkoutCartContentQueryKey,
    queryFn: () =>
      fetchCheckoutCartContent(
        cartId ?? '',
        accessToken ?? '',
        localeCodePathParam
      ) as Promise<IBasketWithDeliveryAndItemDetails>,
    staleTime: Infinity,
    onSuccess: basketDataResponse => {
      if (!shopId) setShopId(basketDataResponse.shopId)
      if (basketDataResponse.isPromoCodeEnabled != null)
        setIsPromoCodeEnabled(basketDataResponse.isPromoCodeEnabled)
    },
    onError: basketContentError => {
      showToast({
        type: 'error',
        title: translated('Failed to fetch cart data')
      })
      console.error(basketContentError)
    }
  })

  const applyCartPromoCodeMutation = useMutation<
    IApplyPromoCodeResponse,
    IApiError,
    { cartId: string; accessToken: string; promoCode: string; localeCode?: string }
  >({
    mutationFn: ({ cartId, accessToken, promoCode, localeCode }) => {
      return applyPromoCode({
        basketId: cartId,
        accessToken,
        promoCode,
        localeCode
      })
    },
    onError: error => {
      console.error(error)
      showToast({
        type: 'error',
        title: promoCodeErrorMessage
      })
    },
    onSuccess: updatedPartialCartData => {
      showToast({
        type: 'success',
        title: translated('Promo code {{promoCode}} applied', {
          promoCode: activePromoCode
        })
      })
      queryClient.setQueryData(checkoutCartContentQueryKey, {
        ...queryClient.getQueryData(checkoutCartContentQueryKey),
        ...updatedPartialCartData
      })
    }
  })

  const basketPromoCode = basketData?.affiliateDiscount?.[0]?.promoCode
  const isBasketPromoCodeLoading = applyCartPromoCodeMutation?.isLoading
  const isBasketPromoCodeError = applyCartPromoCodeMutation?.isError
  const basketPromoCodeError = applyCartPromoCodeMutation?.error

  const setBasketPromoCode = useCallback(
    (promoCode: string) => {
      if (
        isBasketQuerySuccess &&
        !basketPromoCode &&
        cartId &&
        accessToken &&
        !isBasketPromoCodeLoading
      ) {
        setInputPromoCode(promoCode)
        applyCartPromoCodeMutation.mutate({ cartId, accessToken, promoCode, localeCode: appLocale })
      }
    },
    [
      accessToken,
      appLocale,
      applyCartPromoCodeMutation,
      basketPromoCode,
      cartId,
      isBasketPromoCodeLoading,
      isBasketQuerySuccess
    ]
  )

  const goesToVirtualWarehouse = selectedDestination === 'virtual-warehouse'
  const basketLanguages = basketData?.languages
  const deliveryMethods = useMemo(
    () => basketData?.delivery?.options ?? [],
    [basketData?.delivery?.options]
  )
  const eligibleDeliveryMethods = goesToVirtualWarehouse
    ? deliveryMethods?.filter(option => option.supportsVirtualWarehouse)
    : deliveryMethods

  const deliveryMethodsByType = useMemo(
    () =>
      eligibleDeliveryMethods?.reduce(
        (output, deliveryMethod) => {
          return deliveryMethod.type === 'pickup'
            ? { ...output, pickup: [...output.pickup, deliveryMethod] }
            : { ...output, other: [...output.other, deliveryMethod] }
        },
        { pickup: [], other: [] } as Record<'pickup' | 'other', IDetailedDeliveryOption[]>
      ),
    [eligibleDeliveryMethods]
  )

  useEffect(() => {
    if (idOfSelectedShippingMethod || isPickupSelected || !basketData) return

    const savedDeliveryOption = basketData?.delivery?.selected
    const idOfSavedDeliveryOption = basketData?.delivery?.selected?.id

    if (
      savedDeliveryOption &&
      eligibleDeliveryMethods.find(method => method.id === savedDeliveryOption.id)
    ) {
      setIdOfSelectedShippingMethod(idOfSavedDeliveryOption)
      setIsPickupSelected(basketData?.delivery?.selected?.type === 'pickup')
    } else if (eligibleDeliveryMethods?.length === 1) {
      setIdOfSelectedShippingMethod(eligibleDeliveryMethods[0].id)
      setIsPickupSelected(eligibleDeliveryMethods[0]?.type === 'pickup')
    }
  }, [basketData, eligibleDeliveryMethods, idOfSelectedShippingMethod, isPickupSelected])

  useEffect(() => {
    if (!basketLanguages?.length) return

    if (
      basketLanguages?.length > 0 &&
      configuredLanguages?.length <= 1 &&
      configuredLanguages?.length != basketLanguages?.length
    )
      setConfiguredLanguages(basketLanguages)
  }, [setConfiguredLanguages, basketLanguages, configuredLanguages?.length])

  /**
   * if virtual-warehouse selected as destination while selected shipping method does not
   * support VW, reset selected shipping method
   */
  useEffect(() => {
    if (
      selectedDestination === 'virtual-warehouse' &&
      !deliveryMethods?.find(option => option.id === idOfSelectedShippingMethod)
        ?.supportsVirtualWarehouse
    )
      setIdOfSelectedShippingMethod(undefined)
  }, [deliveryMethods, idOfSelectedShippingMethod, selectedDestination])

  const setCartContentMutation = useMutation<IBasketWithItemDetails, IApiError, IBasicBasketItem[]>(
    {
      mutationFn: newCartItems =>
        setBasketContent(cartId ?? '', accessToken ?? '', newCartItems, localeCodePathParam ?? ''),
      onError: error => {
        Object.entries(error?.errors ?? []).forEach(([errorKey, errorValueArray]) =>
          showToast({
            type: 'error',
            title: `Error: ${errorKey}`,
            message: errorValueArray.join(',\n')
          })
        )
      },
      onSettled: () => {
        // synchronize the cart state with the values saved on the back-end
        queryClient.invalidateQueries(cartContentQueryKey)
        emptyBuffer()
      }
    }
  )

  const mapValidProducts = useMemo(() => {
    // filter out the products missing core data
    const arrayValidProducts = (basketData?.items ?? []).filter(product => {
      if (!product?.articleNumber) {
        console.error(`Invalid product: missing article number`)
        showToast({
          type: 'error',
          title: `Invalid product: missing article number`
        })
        return false
      } else if (!product?.details?.attributes?.name) {
        console.error(`Invalid product: missing product name`)
        showToast({
          type: 'error',
          title: `Invalid product: missing product name`
        })
        return false
      } else if (product?.details?.displayPrice == null) {
        console.error(`Invalid product: missing display price`)
        showToast({
          type: 'error',
          title: `Invalid product: missing display price`
        })
        return false
      } else if (!product?.details?.currencyCode) {
        console.error(`Invalid product: missing currency code`)
        showToast({
          type: 'error',
          title: `Invalid product: missing currency code`
        })
        return false
      }
      return true
    })
    return new Map(arrayValidProducts.map(product => [product.articleNumber, product]))
  }, [basketData?.items])

  const mergedCartItems = useMemo(
    () =>
      mergeRemoteBasketWithBuffer(basketData?.items ?? [], cartBuffer).map(
        ({ articleNumber, quantity }) => {
          const product = mapValidProducts.get(articleNumber)
          const restrictionValueList = Object.values(product?.restrictions ?? {})
          const maxAmount =
            restrictionValueList?.length > 0 ? Math.min(...restrictionValueList) : Infinity

          return {
            articleNumber,
            quantity,
            name: product?.details.attributes?.name?.value ?? '',
            image: product?.details.media[0]?.url ?? '',
            price: product?.details.displayPrice ?? 0,
            currency: product?.details.currencyCode ?? '',
            points: product?.details.points ?? 0,
            inStock: product?.inStock ?? true,
            maxAmount,
            amountInUnits: product?.details?.netWeight,
            unit: product?.details?.netWeightUnit,
            supportsVirtualWarehouse: product?.details?.supportsVirtualWarehouse ?? false,
            addOnItems: !!product && 'addOnItems' in product ? product.addOnItems : []
          }
        }
      ),
    [basketData?.items, cartBuffer, mapValidProducts]
  )

  const hasVWIneligibleProducts = (mergedCartItems ?? []).some(
    cartItem => !cartItem?.supportsVirtualWarehouse
  )

  useEffect(() => {
    // whenever the destination changes, reset the ID of the selected shipping method & the pick-up status
    if (selectedDestination) {
      setIdOfSelectedShippingMethod(undefined)
      setIsPickupSelected(false)
    }

    // if selected destination missing from state, set it based on back-end basket config
    if (!selectedDestination && basketData)
      setSelectedDestination(
        basketData?.delivery?.goesToVirtualWarehouse && !hasVWIneligibleProducts
          ? 'virtual-warehouse'
          : 'immediate-purchase'
      )
  }, [basketData, hasVWIneligibleProducts, selectedDestination])

  // Push the local cart buffer content to the back-end cart after a debounce period
  useEffect(() => {
    if (
      cartBuffer.size &&
      cartId &&
      !setCartContentMutation.isLoading &&
      !setCartContentMutation.isError
    ) {
      if (cartBufferDebounceRef.current) clearTimeout(cartBufferDebounceRef.current)
      cartBufferDebounceRef.current = setTimeout(() => {
        setCartContentMutation.mutate(mergedCartItems)
      }, cartBufferSynchronizationDebounceSpan)

      return () => {
        if (cartBufferDebounceRef.current) clearTimeout(cartBufferDebounceRef.current)
      }
    }
  }, [cartBuffer, cartId, mergedCartItems, setCartContentMutation])

  const addUnitsToCart = useCallback(
    (articleNumber: string, quantity: number) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      bufferDispatch({ type: 'addUnits', payload: { articleNumber, quantity } })
    },
    [cartId, setCartContentMutation.isError, setCartContentMutation.isLoading]
  )

  const removeUnitsFromCart = useCallback(
    (articleNumber: string, quantity: number) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      bufferDispatch({ type: 'removeUnits', payload: { articleNumber, quantity } })
    },
    [cartId, setCartContentMutation.isError, setCartContentMutation.isLoading]
  )

  const setCartArticleUnits = useCallback(
    (articleNumber: string, newQuantity: number) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      setCartContentMutation.mutate(
        mergedCartItems.map(cartItem =>
          cartItem.articleNumber === articleNumber
            ? { ...cartItem, quantity: newQuantity }
            : cartItem
        )
      )
    },
    [cartId, mergedCartItems, setCartContentMutation]
  )

  const removeArticleFromCart = useCallback(
    (articleNumber: string) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      setCartContentMutation.mutate(
        mergedCartItems.filter(cartItem => cartItem.articleNumber !== articleNumber)
      )
    },
    [cartId, mergedCartItems, setCartContentMutation]
  )

  const emptyBuffer = useCallback(() => {
    bufferDispatch({ type: 'emptyBuffer' })
  }, [])

  const emptyCart = useCallback(() => {
    if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
    setCartContentMutation.mutate([])
  }, [cartId, setCartContentMutation])

  const isCartUploading = setCartContentMutation.isLoading
  const affiliateDiscount = basketData?.affiliateDiscount?.[0]?.value

  const checkoutProviderValue = useMemo(
    () => ({
      isBasketLoading,
      isBasketQueryError,
      basketQueryError,
      shopId: basketData?.shopId ?? '',
      shopUrl: basketData?.shopUrl ?? '',
      cartCurrency: basketData?.items[0]?.details?.currencyCode ?? 'EUR',
      cartItems: mergedCartItems,
      addUnitsToCart,
      removeArticleFromCart,
      removeUnitsFromCart,
      setCartArticleUnits,
      emptyCart,
      allowsVirtualWarehouseDelivery: basketData?.delivery?.askAboutVirtualWarehouse ?? false,
      askAboutMidnightMadness: basketData?.askAboutMidnightMadness ?? false,
      goesToVirtualWarehouse,
      isPickupSelected,
      setIsPickupSelected,
      idOfSelectedShippingMethod,
      setIdOfSelectedShippingMethod,
      deliveryMethods,
      eligibleDeliveryMethods,
      pickupDeliveryMethods: deliveryMethodsByType.pickup ?? [],
      nonPickupDeliveryMethods: deliveryMethodsByType.other ?? [],
      defaultAddress: basketData?.delivery?.shipTo,
      itemValidationErrorMap,
      setItemValidationErrorMap,
      conditions: basketData?.terms ?? [],
      additionalCharges: basketData?.additionalCharges ?? [],
      totalDeliveryCharges: basketData?.delivery?.selected?.cost.displayPrice ?? 0,
      cartSubtotal: basketData?.totals?.itemsDisplayTotal ?? 0,
      totalTaxes: basketData?.totals?.totalTaxAmount ?? 0,
      taxGroups: basketData?.totals?.taxGroups ?? [],
      cartPoints: basketData?.totals?.points ?? 0,
      totalPaymentDue: basketData?.totals?.roundedTotalGross ?? 0,
      undiscountedTotal: basketData?.totals?.totalGrossExcludingDiscount ?? 0,
      isCartUploading,
      cartStatus: basketData?.status,
      isPaid: basketData?.status === 'paymentAuthorized',
      isCanceled: basketData?.status === 'canceled',
      selectedDestination,
      setSelectedDestination,
      midnightMadnessPointAllocationMonth,
      setMidnightMadnessPointAllocationMonth,
      cartContentQueryKey,
      checkoutCartContentQueryKey,
      affiliateDiscount,
      basketPromoCode,
      isBasketPromoCodeLoading,
      isBasketPromoCodeError,
      basketPromoCodeError,
      setBasketPromoCode,
      promoCodeErrorMessage
    }),
    [
      addUnitsToCart,
      affiliateDiscount,
      basketData?.additionalCharges,
      basketData?.askAboutMidnightMadness,
      basketData?.delivery?.askAboutVirtualWarehouse,
      basketData?.delivery?.shipTo,
      basketData?.delivery?.selected?.cost.displayPrice,
      basketData?.items,
      basketData?.shopId,
      basketData?.shopUrl,
      basketData?.status,
      basketData?.terms,
      basketData?.totals?.itemsDisplayTotal,
      basketData?.totals?.points,
      basketData?.totals?.roundedTotalGross,
      basketData?.totals?.taxGroups,
      basketData?.totals?.totalGrossExcludingDiscount,
      basketData?.totals?.totalTaxAmount,
      basketPromoCode,
      basketPromoCodeError,
      basketQueryError,
      cartContentQueryKey,
      checkoutCartContentQueryKey,
      deliveryMethods,
      deliveryMethodsByType.other,
      deliveryMethodsByType.pickup,
      eligibleDeliveryMethods,
      emptyCart,
      goesToVirtualWarehouse,
      idOfSelectedShippingMethod,
      isBasketLoading,
      isBasketPromoCodeError,
      isBasketPromoCodeLoading,
      isBasketQueryError,
      isCartUploading,
      isPickupSelected,
      itemValidationErrorMap,
      mergedCartItems,
      midnightMadnessPointAllocationMonth,
      promoCodeErrorMessage,
      removeArticleFromCart,
      removeUnitsFromCart,
      selectedDestination,
      setBasketPromoCode,
      setCartArticleUnits
    ]
  )

  return (
    <CheckoutContext.Provider value={checkoutProviderValue}>{children}</CheckoutContext.Provider>
  )
}

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext)

  if (context === null) {
    throw new Error('useCheckoutContext must be used within CheckoutProvider')
  }

  return context
}
